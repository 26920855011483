<template>
  <div>
    <div v-if="isLoading"></div>
    <tag-carousel v-else :items="objectTypes" @select="onChangeType" />
    <Loader v-if="isLoading" />
    <div v-else class="culture mt-8" :key="count">
      <div class="culture__cards" v-if="!!objects ? objects.length > 0 : false">
        <router-link
          v-for="item in objects ? objects : []"
          :key="item.id"
          :to="{
            name: 'info',
            params: { id: item.id }
          }"
        >
          <ObjectCard
            :type="item.type"
            :typeName="item.typeName"
            :address="item.address"
            :extraData="item.extraData"
            :schedule="item.schedule"
            icon="phone"
          >
          </ObjectCard>
        </router-link>
      </div>
      <div class="culture__cards" v-else>
        <h3>Ничего не найдено</h3>
      </div>
      <div class="culture__map-cont">
        <map-search
          :modelSearch="searchText"
          @update:modelSearch="newValue => (searchText = newValue)"
        ></map-search>
        <map-culture
          :garbage="true"
          :objects="objects"
          :balloons="true"
          :componentKey="componentKey"
          :coordinates="coordinates"
        >
        </map-culture>
      </div>
    </div>
  </div>
</template>

<script>
import ObjectCard from '@/components/ObjectCard.vue';
import TagCarousel from '../components/TagCarousel.vue';
import MapCulture from '../components/MapCulture';
import MapSearch from '../components/MapSearch';
import axios from 'axios';
import Loader from '@/components/Loader.vue';

export default {
  name: 'Home',
  components: {
    Loader,
    ObjectCard,
    TagCarousel,
    MapCulture,
    MapSearch
  },

  data() {
    return {
      objectTypes: [
        {
          id: 0,
          title: 'Все',
          active: true
        }
      ],

      count: 0,
      typeId: 0,
      isMapCollapsed: false,
      searchText: null,
      timeout: null,
      componentKey: 0,
      coordinates: null
    };
  },

  computed: {
    allObjects() {
      return this.$store.state.objects;
    },
    objects() {
      const objects = [];
      if (this.allObjects != null) {
        var objectType = [
          'Все',
          'Контейнер',
          'Площадка ТКО',
          'Бункер-накопитель',
          'Мусорокамера',
          'Раздельный сбор'
        ];
        const objectsAll = this.$store.state.objects.all.filter(
          el =>
            el.lat &&
            el.lng &&
            (this.typeId > 0 ? el.type == parseInt(this.typeId) : true) &&
            el.hidden != '1'
        );

        const mapIcons = [
          this.$markerIcon,
          this.$tko,
          this.$wasteBin,
          this.$wasteCam,
          this.$separate,
          this.$markerIconToo
        ];

        const objectsAllItem = objectsAll.map(el => {
          return {
            id: String(el.id),
            address: String(el.address),
            type: parseInt(el.type),
            typeName: String(objectType[parseInt(el.type)]),
            FillingPercentage: el.FillingPercentage ? el.FillingPercentage : ' - ',
            updated: el.updated ? el.updated : ' - ',
            camera_guid: el.camera_guid ? true : false,
            extraData: {
              ...el.extraData,
              clock: el.extraData?.clock ? el.extraData?.clock : '00:00 - 00:00',
              days: el.extraData?.days ? el.extraData?.days : ''
            },
            schedule: el.schedule,
            lat: el.lat,
            lng: el.lng,
            icon: mapIcons[parseInt(el.type) - 1]
          };
        });
        const objectsType = [];
        if (this.objectTypes.length == 1) {
          var i = 0;
          objectType.map(el => {
            if (i != 0) {
              this.objectTypes.push({
                id: i,
                title: el,
                active: false
              });
            }
            i++;
          });
        }
        objects.push(...objectsAllItem);
        if (this.searchText) {
          var object = objects.filter(el => this.onAddress(el.address));
          this.componentKey++;
          if (!!object[0]) {
            this.coordinates = [object[0]?.lat, object[0]?.lng];
          }
          console.log(object);
          console.log(object.length);
          if (object.length == 0 && this.searchText) {
            this.onCoordinates(this.searchText);
          }
          return object;
        } else {
          this.coordinates = [objects[0]?.lat, objects[0]?.lng];
        }
        if (objects?.length == 0) {
          this.coordinates = this.$cityCenter;
        }
        this.componentKey++;
        return objects;
      }
    },
    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },

  async mounted() {
    this.showMap = true;
    this.$store.dispatch('loadObjects');
  },

  methods: {
    onCoordinates(item) {
      if (item) {
        axios
          .get(
            `https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=Волгодонск ${item.trim()}&format=json&lang=ru_RU&kind=house`
          )
          .then(result => {
            var geo = result.data.response.GeoObjectCollection.featureMember?.[0]?.GeoObject;

            if (geo) {
              this.coordinates = geo.Point?.pos?.split(' ').reverse();
              console.log(this.coordinates);
            } else {
              console.log('ппц');
            }
          });
      }
    },
    onAddress(address) {
      let len = 0;
      let arr = this.searchText
        .toLowerCase()
        .replace(/ +/g, ' ')
        .trim()
        .split(' ');
      arr.forEach(function(item) {
        if (address.toLowerCase().indexOf(item) >= 0) {
          len++;
        }
      });
      return address ? len == arr.length : false;
    },
    onChangeType(type) {
      this.typeId = type.id;
      this.count++;
    }
  }
};
</script>
<style lang="scss" scoped>
.culture {
  display: flex;
  justify-content: space-between;

  &__cards {
    width: 41.7%;
  }
  &__map-cont {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    height: 648px;
    width: calc(58.3% - 32px);
    margin-left: 32px;
  }
}

.balloon-staff {
  display: grid;
  grid-template-columns: auto 1fr;
}

@media screen and (max-width: 1023px) {
  .culture__map-cont {
    position: relative;
    top: 0;
    width: 100%;
    margin-left: 0px;
    height: auto;
    margin-bottom: 20px;
  }
  .culture {
    max-width: 100%;
    flex-direction: column-reverse;

    &__cards {
      width: 100%;
    }
  }
}
@media screen and (min-width: 600px) {
  .culture {
    &__input-search {
      position: absolute;
      z-index: 10;
      background-color: #fff;
      top: 16px;
      left: 16px;
    }
  }
}
</style>
