<template>
  <div id="app">
    <transition>
      <router-view />
    </transition>
    <r-notification />
  </div>
</template>
<script>
export default {
  beforeCreate() {
    // Удаляем script от пред. приложения
    document.getElementById('vue-yandex-maps') &&
      document.getElementById('vue-yandex-maps').remove();

    // Удаляем ymaps
    window.ymaps && delete window.ymaps;
  }
};
</script>
<style lang="scss">
.align-center {
  align-items: center;
}
body[class*='-fullscreen'] {
  .culture__map-cont {
    position: relative;
  }
}

.rir-map-balloon__claster {
  .briscola.clock {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #04153e;
  }
  .roquefort {
    color: #0e1420;
  }
  .rir-map-balloon__address {
    position: static;
    height: 12px;
    left: 0px;
    right: 91px;
    top: 52px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #04153e;
    opacity: 0.72;
  }
}
.RBulb__badge.fargo.marina.eluno.title.block {
  background-color: unset !important;
}
.RButtonSimple.RModal__close.larishae.transparent.rocky {
  background-color: unset !important;
}
.RButtonAction.rocky.sulguni {
  background-color: unset !important;
}
</style>
<style>
</style>
