<template>
  <div class="r-map" ref="map">
    <yandex-map
      v-if="isYmapsReady"
      :coords="center || $cityCenter"
      :zoom.sync="mapZoom"
      @click="onMapClick"
      :options="mapOptions"
      :controls="mapControls"
      @map-was-initialized="onMapInit"
      :cluster-options="clusterOptions"

      @contextmenu="onRightClick"
      @markers-was-add="$emit('markersWasAdd', $event)"
      @markers-was-change="$emit('markersWasChange', $event)"
    >
      <slot />
    </yandex-map>
    <div
      v-if="garbage"
      class="r-map__legend"
    >
      <div class="flex">
        <div
          class="ml-2 flex align-center"
        >
          <img
            class="legend__img"
            src="../assets/tko.svg"
          />
          <span class="briscola opacity-72">Мусоровозы</span>
        </div>
      </div>
    </div>
    <div v-show="control" :class="isCollapsed ? 'r-map__controls' : 'r-map__controls--fixed'">
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        class="mb-4 amelie"
        :icon="isCollapsed ? 'fullscreen' : 'exit-fullscreen'"
        fill="rocky"
        icon-size="20"
        @click="isCollapsed = !isCollapsed"
      />
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        icon="add"
        class="amelie"
        fill="rocky"
        icon-size="20"
        @click="onZoom(1)"
      />
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        class="mt-1 mb-4 amelie"
        icon="remove"
        fill="rocky"
        icon-size="20"
        @click="onZoom(-1)"
      />
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        v-if="locationBtn"
        icon="location"
        icon-size="20"
        @click="onLocation"
      />
    </div>
  </div>
</template>

<script>
import BalloonCard from './BalloonCard.vue';
import { loadYmap } from 'vue-yandex-maps';

export default {
  name: 'rir-map',
  components: {
    BalloonCard,
    loadYmap
  },
  props: {
    control: {
      type: Boolean,
      default: true
    },
    collapseBtn: {
      type: Boolean,
      default: false
    },
    locationBtn: {
      type: Boolean,
      default: true
    },
    garbage: {
      type: Boolean,
      default: false
    },
    center: {
      type: Array
    },
    zoom: {
      type: Number,
      default: 14
    },
    maxZoom: {
      type: Number,
      default: 20
    },
    minZoom: {
      type: Number,
      default: 12
    },
    // Перетаскивание маркера
    markerDrag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isYmapsReady: false, // Начальное значение
      mapControls: ['fullscreenControl'], // [] - Скрыть все элементы управления картой. ['fullscreenControl'] - кнопка развернуть на весь экран
      mapOptions: {
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: true,
        balloonPanelMaxMapArea: 0
      },
      //   coords: this.initCoords,
      mapZoom: this.zoom,
      mapInstanse: null,
      timeout: null,
      userPosition: null,
      isCollapsed: true,
      /*clickOnCluster: {
        main: {
          click: function(e) {
            const target = e.get('target');
            const customItemContentLayout = window.ymaps.templateLayoutFactory.createClass(
              '<div>{{geoObject.properties.balloonContentBody|raw}}</div>'
            );
            target.options.set({ clusterBalloonItemContentLayout: customItemContentLayout });
          }
        }
      },*/
      clusterOptions: {
        main: {
          hideIconOnBalloonOpen: false,
          clusterize: true,
          clusterHasHint: true,
          // ObjectManager принимает те же опции, что и кластеризатор.

          gridSize: 32,
          /*   clusterIconLayout: 'default#pieChart',
             iconPieChartCoreFillStyle: '#3D75E4',
             clusterIconPieChartStrokeWidth:6,
             clusterIconPieChartCoreRadius: 20,
             clusterIconPieChartRadius: 28, */
          clusterDisableClickZoom: true,
          clusterBalloonLayout: [
            '<ul class="list" style="margin: 24px 0px 24px 24px;max-width:250px;  max-height: 200px;overflow-y: scroll;">',
            '{% for geoObject in properties.geoObjects %}',
            '<li class="mt-2 mr-4">'
            + '<a href="/control-tko/info/{{ geoObject.properties.balloonContentHeader.id|raw }}" class="list_item roquefort" >'
            + '{{ geoObject.properties.balloonContentHeader.name|raw }}</a></li>',
            '{% endfor %}',
            '</ul>'
          ].join(''),
          // Иконка кластера
          clusterIcons: [
            {
              href: process.env.VUE_APP_MAP_CLUSTER_ICON,
              size: [64, 64],
              offset: [-24, -24]
            }
          ]
          //   Шрифт кластера
        }
      }
    };
  },
  async mounted() {
    // настройки карты
    const settings = {
      apiKey: 'f8217e42-9b86-4033-8411-a7bf4f8d6835',
      lang: 'ru_RU',
      coordorder: 'latlong',
      version: '2.1'
    };

    !window.ymaps && (await loadYmap(settings));
    this.isYmapsReady = true;
  },
  updated() {
    console.log('updated');
    // this.mapInstanse.container.fitToViewport();
  },
  methods: {
    unclasteredMarkers(e) {
      console.log('unclasteredMarkers', e);
    },

    onMapInit(e) {
      //  console.log('mapInit | objects - ', e.geoObjects.getLength(), e.geoObjects);
      this.mapInstanse = e;
      this.$emit('onInit', this.mapInstanse);
      this.setObserver();
      this.mapInstanse.options.set('openBalloonOnClick', false);
    },
    onDragMarker(e) {
      console.log('drag', e);
    },
    onZoom(val) {
      const newZoom = this.mapZoom + val;
      if (newZoom <= this.maxZoom && newZoom >= this.minZoom) this.mapZoom = newZoom;
    },
    onLocation() {
      console.log('onLocation');
      const geolocation = window.ymaps.geolocation;
      geolocation
        .get({
          mapStateAutoApply: true
        })
        .then(result => {
          this.mapInstanse.geoObjects.add(result.geoObjects);
        });
    },
    onMapClick(e) {
      const coords = e.get('coords');
      this.coords = coords;
      //   Закрываем открытый Balloon при клике по карте
      this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
      this.$emit('click', coords);
    },
    onRightClick(e) {
      this.$emit('onRightClick', e);
    },
    setObserver() {
      //   Вызываем метод fitToViewport если изменились размеры родительского элемента
      const myObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.mapInstanse.container.fitToViewport();
          }, 50);
        });
      });

      myObserver.observe(this.$refs.map.parentElement);
    }
  },

  watch: {
    isCollapsed() {
      if (!this.isCollapsed) {
        this.$refs.map.querySelector('.ymaps-2-1-79-float-button-icon_icon_expand').click();
      } else {
        document.querySelector('.ymaps-2-1-79-float-button-icon_icon_collapse').click();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  &__search {
    position: absolute;
    border-radius: 8px;
    z-index: 10;
    top: 16px;
    left: 16px;
    display: flex;
    width: 255px;
    box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
    > * {
    }
  }
  &__controls {
    display: block !important;
    position: absolute;
    top: 16px;
    right: 16px;
    > * {
      width: 40px;
      height: 40px;
      box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
    }
    &--fixed {
      position: fixed !important;
      right: 16px;
      top: 16px;
      z-index: 10001;
    }
  }
  &--fixed {
    background-color: #ccc;
    border-radius: unset;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    opacity: 1;
  }

  &__legend {
    padding: 8px 12px;
    background: var(--rir-amelie);
    position: absolute;
    left: 16px;
    bottom: 16px;
    border-radius: 4px;
  }
}

.legend {
  &__color {
    margin-right: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    flex-shrink: 0;
  }
  &__img {
    margin-right: 6px;
    height: 16px;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 1023px) {
  .r-map {
    &__search {
      display: none;
    }
  }
}
// yaMap
::v-deep .ymap-container {
  width: 100%;
  height: 100%;
}

::v-deep .ymaps-2-1-79-map {
  max-height: 100%;
  overflow: hidden;
}

// Стилизация PopUp'a
::v-deep .ymaps-2-1-79-balloon {
  /*bottom: calc(99% - -14px);
   left: 0!important;
   top: unset!important;*/
  padding: 0;
  margin: 0;
  box-shadow: none !important;
  top: unset !important;
  bottom: 10px;
  @media (max-width: 599px) {
    padding: 0 5px;
    background: transparent;
    border-radius: 16px 16px 16px 16px;
  }
}

// Скрытие "хвостика" PopUp'a
::v-deep .ymaps-2-1-79-balloon__tail,
.ymaps-2-1-79-balloon__tail:after {
  display: none;
}

::v-deep .ymaps-2-1-79-b-cluster-carousel__nav {
  background-color: #ffffff;
  box-shadow: 0px 2px 8px rgba(4, 21, 62, 0.12);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

::v-deep .ymaps-2-1-79-b-cluster-carousel__nav_type_prev {
  left: -16px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='16' viewBox='0 0 6 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.83776 2.04161C6.13682 1.57729 6.00285 0.958464 5.53854 0.65941C5.37077 0.551352 5.18282 0.49983 4.99708 0.5C4.66876 0.500296 4.34732 0.662091 4.15633 0.958637L0.667321 6.37568C0.665633 6.3783 0.663949 6.38092 0.66227 6.38355L0.318578 6.91716C-0.106193 7.57667 -0.106193 8.4236 0.31858 9.0831L0.662295 9.61675C0.663967 9.61937 0.665643 9.62198 0.667324 9.62459L4.15633 15.0416C4.26847 15.2157 4.4256 15.3434 4.60267 15.4192C4.85237 15.5261 5.14174 15.5298 5.40067 15.4152C5.44778 15.3944 5.49387 15.3696 5.53854 15.3408C6.00285 15.0418 6.13682 14.4229 5.83776 13.9586L2.34874 8.54162C2.34777 8.5401 2.34679 8.53858 2.34583 8.53706C2.13733 8.20949 2.13733 7.79076 2.34583 7.46319L5.83776 2.04161Z' fill='%233D75E4'/%3e%3c/svg%3e ") !important;
  background-position: 48% 50% !important;
  background-repeat: no-repeat;
}
::v-deep .ymaps-2-1-79-b-cluster-carousel__nav_type_next {
  right: -16px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='16' viewBox='0 0 6 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.16234 2.04161C-0.136714 1.57729 -0.00274534 0.958464 0.461566 0.65941C0.629338 0.551352 0.817283 0.49983 1.00303 0.5C1.33134 0.500296 1.65278 0.662091 1.84378 0.958637L5.33278 6.37568C5.33447 6.3783 5.33615 6.38092 5.33783 6.38355L5.68153 6.91716C6.1063 7.57667 6.1063 8.4236 5.68152 9.0831L5.33781 9.61675C5.33614 9.61937 5.33446 9.62198 5.33278 9.62459L1.84378 15.0416C1.73163 15.2157 1.57451 15.3434 1.39743 15.4192C1.14774 15.5261 0.858359 15.5298 0.599431 15.4152C0.552327 15.3944 0.506231 15.3696 0.461565 15.3408C-0.0027463 15.0418 -0.136714 14.4229 0.162341 13.9586L3.65136 8.54162C3.65234 8.5401 3.65331 8.53858 3.65428 8.53706C3.86278 8.20949 3.86278 7.79076 3.65427 7.46319L0.16234 2.04161Z' fill='%233D75E4'/%3e%3c/svg%3e ") !important;
  background-position: 54% 50% !important;
  background-repeat: no-repeat;
}

// Иконка закрытия Baloon'a
::v-deep .ymaps-2-1-79-balloon__close-button {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon__layout {
  overflow: visible !important;
  background: transparent !important;
}

::v-deep .ymaps-2-1-79-balloon__content {
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
  border-radius: 16px 16px 16px 0px;

  & > [id^='id_'] {
    overflow: visible !important;
  }
}

::v-deep .ymaps-2-1-79-b-cluster-carousel__separator {
  display: none !important;
}

::v-deep
  .ymaps-2-1-79-b-cluster-carousel_pager_marker
  .ymaps-2-1-79-b-cluster-carousel__pager-item {
  background: #e4edfb !important;
  width: 4px !important;
  height: 4px !important;
  margin: 0 2px !important;
}

::v-deep
  .ymaps-2-1-79-b-cluster-carousel_pager_marker
  .ymaps-2-1-79-b-cluster-carousel__pager-item.ymaps-2-1-79-b-cluster-carousel__pager-item_current_yes,
::v-deep
  .ymaps-2-1-79-b-cluster-carousel_pager_marker
  .ymaps-2-1-79-b-cluster-carousel__pager-item:hover {
  background: #3d75e4 !important;
}

::v-deep .ymaps-2-1-79-b-cluster-carousel__pager {
  bottom: 0px !important;
  left: 0;
}

::v-deep .ymaps-2-1-79-b-cluster-carousel {
  padding: 16px 16px 28px 16px !important;
  margin: 0 !important;
  border: none !important;
}

::v-deep .ymaps-2-1-79-b-cluster-carousel__content,
::v-deep .ymaps-2-1-79-balloon__content > [id^='id_'],
::v-deep .ymaps-2-1-79-b-cluster-carousel {
  height: auto !important;
}
::v-deep .ymaps-2-1-79-balloon__layout * {
}

// // HTML элемент иконки кластера
// ::v-deep .ymaps-2-1-79-default-cluster {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   // HTML элемент в котором лежит цифра (кол-во элементов кластера)
//   > * {
//     color: #ffffff;
//     font-weight: bold;
//     font-family: Golos;
//     font-size: 24px;
//     line-height: 28px;
//   }
// }
</style>

<style>
/* HTML элемент иконки кластера */
.ymaps-2-1-79-default-cluster {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* HTML элемент в котором лежит цифра (кол-во элементов кластера) */
.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}

.ymaps-2-1-79-float-button {
  display: none !important;
}
</style>
